.App {
    min-height: 100%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    display: flex;
    color: white;
    padding: 1.5rem;
    background: #282c34;
}

.App-header a.logo {
    color: white;
    font-size: 1.85rem;
}

.App-header a {
    color: white;
    font-size: 1.1rem;
    line-height: 1.2;
    margin-right: 2.5rem;
}

.App-header a:hover {
    color: gold;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ui.container.padded {
    padding: 2rem;
    text-align: left;
}

.ui.form {
    text-align: left;
}

.light-grey {
    background: #fafafa;
}

.SearchHeader {
    /*position: absolute !important;*/
    /*right: 0.5rem;*/
}

.cartItem {
    border-radius: 0.2rem;
    background: #FFF;
    padding: 1rem 1rem 1rem 1rem;
    margin-top: 1rem;
    line-height: 1.5;
}

.controlCartItem {
    line-height: 1;
    margin-top: 1rem;
}

.controlCartItem input {
    outline: none;
    border: 0;
    text-align: center;
}

.controlCartItem .icon {
    cursor: pointer;
}

.selectBank {
    padding: 0.5rem 0;
    border-radius: 0.5rem;
    cursor: pointer;
}

.selectBank.selected,
.selectBank:hover {
    background: #f4f4f4;
}

#root {
    height: 100%;
    overflow: auto;
}

.mainBackground {
    background: #282c34;
    color: #ffffffeb;
}

.secondBackground {
    background: #282c34e8;
}

.secondBackground.fluid {
    flex: auto;
}

.mainBorderRadius {
    border-radius: 0.5rem;
    padding: 1.5rem;
}

.colorGold {
    color: gold;
}

.orderList {
    background: #282c34 !important;
    margin-bottom: 3rem !important;
    padding: 0 !important;
    border-radius: 0.5rem;
    overflow: hidden;
}

.orderList .header {
    background: #ffffffe0;
    color: #000;
    padding: 0.4rem 1.2rem;
}

.orderList .content {
    padding: 1.2rem 1.2rem;
    background: #f7f7f7;
}

.orderList .fixLabel label {
    color: #ffffffc4 !important;
    font-weight: 100 !important;
}

.OrderItemList {
    margin: 0 0 1rem 0;
    padding: 1rem;
    background: #f0f0f0;
    border-radius: 0.3rem;
}

.indent {
    text-indent: 3rem;
}

img {
    max-width: 100%;
    height: auto;
}

.highlight2 {
    background-color: rgb(245, 245, 245);
    padding: 2rem;
    border-radius: 1.5rem;
    margin-bottom: 2rem;
}
